//yarn start
//import "assets/css/style.scss";
function log(e){"undefined"!=typeof webkit?webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify({action:"log",value:e})):"undefined"!=typeof android?android.log(e):console.log(e)}function ajax_get(e,t,a){ajax_access("GET",e,t,a,!0)}function ajax_post(e,t,a){ajax_access("POST",e,t,a,!0)}function post(path,data){ajax_access("POST",path,data,function(d){eval(d)},!0)}function ajax_access(e,t,a,n,o){if("[object Object]"===Object.prototype.toString.call(a)){var r=new FormData;for(var c in a)r.append(c,a[c]);a=r}if(!$1("#loading")||!$1("#loading").classList.contains("start")){if(null==t)return null;var i=null;if(window.XMLHttpRequest)try{i=new XMLHttpRequest}catch(l){}else if(window.ActiveXObject)try{i=new ActiveXObject("Msxml2.XMLHTTP")}catch(l){try{i=new ActiveXObject("Microsoft.XMLHTTP")}catch(l){}}if(null==i)return null;e=e||"GET",o=o||!0,i.open(e,t,o),i.setRequestHeader("X-Requested-With","XMLHttpRequest"),i.onreadystatechange=function(){4==i.readyState&&200==i.status&&null!=n&&n(i.responseText),4==i.readyState&&$1("#loading")&&($1("#loading").className="")},$1("#loading")&&($1("#loading").className="start"),i.send(a)}}void 0===window.$$&&(window.$$=function(e,t){var a=t?t:document,n=/^<([\w-]+)\s*\/?>(?:<\/\1>|)$/.exec(e);if(n)return a.createElement(n[1]);var o=a.querySelectorAll(e);if(o.length>0){array=[];for(var r=0;r<o.length;r++)array[r]=o[r];return array}return null}),void 0===window.$1&&(window.$1=function(e,t){var a=t?t:document,n=a.querySelector(e);return n?n:null});
window.addEventListener("load",function(){
  if ($1("#btn_menu")) $1("#btn_menu").addEventListener('click',toggleMenu);
  $$("[href]").forEach(function(elm){
    elm.addEventListener("click",jumpLink);
  });
  showPage();
  /*
  $(function () {
    var name = "comodolope";
    //prefixedUrl = 'https://www.instagram.com/' + name+'/';
    prefixedUrl = 'https://images' + ~~(Math.random() * 3333) + '-focus-opensocial.googleusercontent.com/gadgets/proxy?container=none&url=https://www.instagram.com/' + name+'/';
    
    $.ajax(prefixedUrl, {
      timeout: 2000,
      datatype: 'html'
    }).then(function (data) {
      //console.log(data);
      json_string = data.split("window._sharedData = ")[1];
      json_string = json_string.split("};<"+"/script>")[0] + "}";
      var array = JSON.parse(json_string);
      let datas = array.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
        //表示件数12の場合
        for (var i=0; i<8; i++) {
          url = datas[i].node.display_url;
          this.html = `
              <a target="_blank" href="https://www.instagram.com/${name}/" style="background-image: url(${url});"></a>
          `;
          $("#insta").append(this.html);
        }
    });
    
  });
  */
  
});
var toggleMenu = function(){
  if ($1("#container").classList.contains("close")) {
    $1("#container").classList.add("pre");
    $1("#container").classList.remove("close");
    setTimeout(function(){
      $1("#container").classList.remove("pre");
    },500);
  }
  else {
    $1("#container").classList.add("close");
  }
}
var jumpLink = function(e) {
  var target = e.target;
  if (typeof(target.href)=='undefined') target = target.parentNode;
  if (typeof(target.href)=='undefined') target = target.parentNode;
  //console.log('jump to '+target.href);
  history.replaceState('','',target.href);

  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  showPage();
  return;
};
var showPage = function() {
  var pos = location.href.lastIndexOf("/");
  if (pos != -1) {
    var page = location.href.substr(pos+1);
    //console.log(page);
    if (!$1("#page_"+page)) return;
    
    if ($1("#container").classList.contains("close")) toggleMenu();
    $1("#main").classList.remove("anim_show");
    $1("#main").classList.add("anim_hide");
    scrollTo(0,0);
    setTimeout(function(){
      for (var a=0;a<$1("#main").children.length; a++) {
        $1("#hide").appendChild($1("#main").children[a]);
      }
      
      $1("#main").appendChild($1("#page_"+page));
      $1("#main").classList.add("anim_show");
      $1("#main").classList.remove("anim_hide");
    },500);
  }
  else {
    console.log("location.href:"+location.href);
  }
}
/*
*/
/*
window.addEventListener("beforeunload",function(e){
  console.log("before");
  e.preventDefault();
//    e.returnValue = '';
//    return "fafafa";
});
*/
/*
import '@riotjs/hot-reload'
import App from './app.riot'
import {component} from 'riot'

component(App)(document.getElementById('app'), {
  title: 'Hello Riot.js!'
})
*/
